import React, { FormEvent, useEffect, useState, useContext } from 'react'
import { Link } from 'react-router-dom'
import login from '../../assets/images/login.svg'
import ReactLoading from 'react-loading'
import { useToasts } from 'react-toast-notifications'
import axios from 'axios'
import  jwt from 'jwt-decode'
import Button from 'react-bootstrap/Button';
import { authContext } from '../../contexts/AuthContext';


const Login = () => {
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')
  const [isVisible, setIsVisible] = useState(false)
  
  const { addToast } = useToasts()
  const { setExp, setIsAuthenticated } = useContext(authContext)

  const handleLogin = async(e: FormEvent) => {
    e.preventDefault()
    
    setLoading(true)
    
    if(!username || !password) {
        addToast("Please fill in your details", {appearance: "error"})
        setLoading(false)
    }

    if(username && password){

        const data = {
            username,
            password
        }

        axios.post("http://localhost:5000/user/login", data)
            .then(res => {
                
            localStorage.setItem("access-token", res.data.data.token)
            localStorage.setItem("user", JSON.stringify(res.data.data.user))
            const user = jwt<{exp:number}>(res.data.data.token)
            
            setIsAuthenticated(true)
            setExp(user.exp)
            setLoading(false)
            
            addToast(res.data.message, {appearance: "success"})
            return window.location.href = ("/");

            })
            .catch(err => {
                setLoading(false)
                if(err.message === "Network Error") return addToast("Network Error", {appearance: "error"})
                else {
                  setError(err.response.data.message)
                  addToast(err.response.data.message, {appearance: "error"})
                }
                
            })

        // if(!res.data.error){

        // }else{
        // }


      }
      return
  }

  useEffect(()=> {
    setTimeout(() => setError(''), 5000)
  }, [error])

  return (
    <div className='auth'>
      <img src={login} alt="login" />
      <div className='auth_input'>
        <form>
          <h2>Login</h2>

        {error && <p style={{color: 'red', fontSize: '12px'}}>{error}</p>}

          <input value={username} onChange={(e) => setUsername(e.target.value)} type="text" placeholder='Username' />
          <div className='password_input'>
                <input value={password} onChange={(e) => setPassword(e.target.value)} type={ isVisible ? "text" : "password"} placeholder='Password' />
                <span className='togglePass' onClick={() => setIsVisible(!isVisible)}>{ isVisible ? <span>Hide</span> : <span>Show</span> }</span>
            </div>

          <Button className='rounded' onClick={handleLogin} > {loading ? <ReactLoading color="#fff" width={30} height={30} type="spin" /> : "Login"} </Button>

        </form>
        <p className='dont_'>Don't have an account? <Link to="/register"><span>Register</span></Link></p>
      </div>
    </div>
  )
}

export default Login