import React from 'react'
import Header from '../components/Header'
import Container from 'react-bootstrap/Container';
import Footer from '../components/Footer';

const Layout: React.FC = ({ children }) => {
  return (
    <div>
        <Header />
        <Container className="mt-5 pt-5">
            {children}
        </Container>
        <Footer/>
    </div>
  )
}

export default Layout