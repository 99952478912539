import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import Button from 'react-bootstrap/Button'
import { deleteData, getRequest, postData } from '../../utils/Api'
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import ReactLoading from 'react-loading'
import axios from 'axios'
import { useToasts } from 'react-toast-notifications';
import moment from 'moment'


const Story = () => {
  const [story, setStory] = useState({
    title: '',
    description: ""
  })
  const [modalShow, setModalShow] = useState(false)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState('')

  const { addToast } = useToasts()

  // Story id
  let {id} = useParams()

  const getStory = () => {
    try{
      const response = getRequest(process.env.REACT_APP_API_URL_STORIES + id)
      response.then((res) => {
        setStory(res.data.data)
        setLoading(false)
      })
    }catch(err){
        setError(err)
    }
  }

  
  const updateStory = async () => {
    const data = story
    try {
      const response = await postData(process.env.REACT_APP_API_URL_STORIES + id, data)
      response.then(res => {
        addToast("Story Updated Successfully", { appearance: "success"})

      })
    } catch (error) {
      if(axios.isAxiosError(error)){
        setError(error.message)
        addToast("Error Deleting Story", { appearance: "error"})
      }
    }
    setModalShow(false)
  }

  const deleteStory = async() => {
      try {
        const response = await deleteData(process.env.REACT_APP_API_URL_STORIES + id)

        if(response.data.data){
          addToast(response.data.message, { appearance: "success"})
          setTimeout(() => addToast(window.location.href = '/', 2000))
        }else{
          return addToast(response.data.message, { appearance: "error"})
        }
        
        
      } catch (error) {

        addToast("Error Deleting Story", { appearance: "error"})
      }
  }

    useEffect(() => {
        getStory()
    }, [])

  return (
    <>
        <div>
        <div className='d-flex justify-content-between align-items-right w-100'>
            <span></span>
            <Button variant="primary" onClick={() => setModalShow(true)}>Edit</Button>
        </div>
      {
        loading ? <ReactLoading color="blue" width={30} height={30} type="spin" /> : 
          <div className='my-5'>
        <div className='d-flex align-items-center mb-4 text-italics'>
             <small className="font-italics text-capitalize"><strong>{story.userId.username}</strong></small>
            <small className="text-muted mx-4">{moment(story.updatedAt).format('lll')}</small>
        </div>
            <h3>{story.title}</h3> 
            <p>{story.description}</p>
          </div> 
      }
          <div className='mt-3 d-flex align-items-center justify-content-between'>
            <Link to="/"><Button variant="secondary">Back</Button></Link>
            <Button variant="danger" onClick={deleteStory}>Delete</Button>
          </div>
  
            {/* // Modal */}
            <Modal
              show={modalShow}
              size="lg"
              aria-labelledby="contained-modal-title-vcenter"
              centered
              onHide={() => setModalShow(false)}
              >
              <Modal.Header closeButton>
                  <Modal.Title id="contained-modal-title-vcenter">
                  Create New Story
                  </Modal.Title>
              </Modal.Header>
              <Modal.Body>
              <Form>
                  <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                  <Form.Label>Title</Form.Label>
                  <Form.Control
                      type="text"
                      placeholder="Title"
                      autoFocus
                      value={story.title}
                      onChange={(e) => setStory({...story, title: e.target.value})}
                  />
                  </Form.Group>
                  <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlTextarea1"
                  >
                  <Form.Label>Description</Form.Label>
                  <Form.Control 
                  as="textarea" 
                  rows={3} 
                  onChange={(e) => setStory({...story, description: e.target.value})}
                  value={story.description} />
                  </Form.Group>
              </Form>
              </Modal.Body>
              <Modal.Footer>
                  <Button variant="success" onClick={updateStory}>Save</Button>
              </Modal.Footer>
          </Modal>
      </div>
    </>
  )
}

export default Story