import React, { useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import './App.css'
import Login from './pages/Auth/Login';
import Register from './pages/Auth/Register';
import Home from './pages/Home';
import About from './pages/Home/About';
import './assets/styles/auth.scss'
import Layout from './Layout/Layout'
import Story from './pages/Home/Story';
import MyStory from './pages/Home/MyStory';

const App: React.FC = () => {

  return (
    <>
    <Layout> 
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/" element={<Home />} />
        <Route path="/:id" element={<Story />} />
        <Route path="/about" element={<About /> } />
        <Route path="/my-stories" element={<MyStory /> } />
      </Routes>
    </Layout> 
    </>
  );
}

export default App;
