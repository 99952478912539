import React, { FormEvent, useEffect, useState, useContext } from 'react'
import { Link } from 'react-router-dom'
import register from '../../assets/images/register.svg'
import { useToasts } from 'react-toast-notifications'
import axios from 'axios'
import Button from 'react-bootstrap/Button';
import ReactLoading from 'react-loading'
import  jwt from 'jwt-decode'
import { authContext } from '../../contexts/AuthContext';


const Register: React.FC = () => {
  const [name, setName] = useState('')
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')
  const [isVisible, setIsVisible] = useState(false)
  
  const { addToast } = useToasts()
  const { setExp, setIsAuthenticated } = useContext(authContext)

  const handleRegister = async(e: FormEvent) => {
    e.preventDefault()

    setLoading(true)

    if(!name || !username || !password) {
        addToast("Please fill in your details", {appearance: "error"})
        setLoading(false)
    }
    

    if(name && username && password){
        const data = {
            name,
            username,
            password
        }

        axios.post("http://localhost:5000/user/register", data)
        .then(res => {
               
            localStorage.setItem("access-token", res.data.data.token)
            localStorage.setItem("user", JSON.stringify(res.data.data.user))
            const user = jwt<{exp:number}>(res.data.data.token)
            
            setIsAuthenticated(true)
            setExp(user.exp)    
            addToast(res.data.message, {appearance: "success"})

            setLoading(false)
            return window.location.href = "/";
        })
        .catch(err => {
            setLoading(false)
            if(err.message === "Network Error") return addToast("Network Error", {appearance: "error"})
            setError(err.response.data.message)
            addToast(err.response.data.message, {appearance: "error"})
            
        })


        
    }
  }
  
  useEffect(()=> {
    setTimeout(() => setError(''), 5000)
  }, [error])

  

  return (
    <div className='auth register'>
      <img src={register} alt="register" />
      <div className='auth_input'>
        <form>
          <h2>Register</h2>

          {error && <p style={{color: 'red', fontSize: '12px'}}>{error}</p>}

          <input value={name} onChange={(e) => setName(e.target.value)} type="name" placeholder='Name' />
          <input value={username} onChange={(e) => setUsername(e.target.value)} type="text" placeholder='Username' />
            <div className='password_input'>
                <input value={password} onChange={(e) => setPassword(e.target.value)} type={ isVisible ? "text" : "password"} placeholder='Password' />
                <span className='togglePass' onClick={() => setIsVisible(!isVisible)}>{ isVisible ? <span>Hide</span> : <span>Show</span> }</span>
            </div>
          <Button onClick={handleRegister} > {loading ? <ReactLoading color="#fff" width={30} height={30} type="spin" /> : "Register" } </Button>
        </form>

        <p className='dont_'>Already have an account? <Link to="/login"><span>Login</span></Link></p>
      </div>
    </div>
  )
}

export default Register