import React, { useState, useEffect, useContext } from 'react'
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { Link } from 'react-router-dom';
import { getRequest } from '../../utils/Api';
import ReactLoading from 'react-loading'
import moment from 'moment'

const MyStory = () => {
    const [stories, setStories] = useState([]);
    const [modalShow, setModalShow] = useState(false);
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState('')

    const saveNewStory = () => {
        setModalShow(false)
    }

    const getStories = () => {
        const { username } = JSON.parse(localStorage.getItem('user'))
        try{
            const response = getRequest(process.env.REACT_APP_API_URL_MY_STORIES + username)
            response.then(res => {
                setLoading(false)
                try {
                    setStories(res.data.data)
                    
                } catch (error) {
                    setError(error)  
                }
            })
        }catch(err){
            setError(err)
        }
    }


    useEffect(() => {
        getStories()
    }, [])


  return (
    <div>
        <div className='d-flex justify-content-between align-items-center'>
            <h3>My User Stories</h3>
            <Button variant="primary" onClick={() => setModalShow(true)} >+ Create New Story </Button>
        </div>

        {/* // Modal */}
        <Modal
            show={modalShow}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            onHide={() => setModalShow(false)}
            >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                Create New Story
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <Form>
                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                <Form.Label>Title</Form.Label>
                <Form.Control
                    type="text"
                    placeholder="Title"
                    autoFocus
                />
                </Form.Group>
                <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlTextarea1"
                >
                <Form.Label>Description</Form.Label>
                <Form.Control as="textarea" rows={3} />
                </Form.Group>
            </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="success" onClick={saveNewStory}>Save</Button>
            </Modal.Footer>
        </Modal>

        <div className='user-stories mt-5'>
            <Row xs={1} md={2} className="g-4">
                {error && <p className="text-center">Unable to load stories.</p>}
                {
                loading && !error ? <ReactLoading color="blue" width={50} height={50} type="spin" /> : (
                (stories === []) ? (
                    <div className='text-center'>
                        <p>No Story Available</p>
                    </div>
                 )
                :
                stories.map((story, idx) => (
                    <Col key={idx}>
                    <Link to={`/${story._id}`}>
                        <Card>
                            <Card.Body>
                            <Card.Title>{story.title}</Card.Title>
                            <Card.Text>
                                {story.description}
                            </Card.Text>
                            </Card.Body>
                            <Card.Footer className="d-flex justify-content-between">
                                <small className="font-italics text-capitalize"><strong>{story.userId.username}</strong></small>
                                <small className="text-muted">{moment(story.updatedAt).format('lll')}</small>
                            </Card.Footer>
                        </Card>
                    </Link>
                    </Col>
                ))
                )}
            </Row>
        </div>
    </div>
  )
}

export default MyStory