import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { authContext } from '../contexts/AuthContext';


const Header = () => {
  const { isAuthenticated, setIsAuthenticated } = useContext(authContext)

  const logoutUser = () => {
    localStorage.clear()
    setIsAuthenticated(false)
  }

  return (
    <Navbar fixed="top" collapseOnSelect expand="lg" bg="dark" variant="dark">
    <Container className='d-flex justify-content-between'>
      <Navbar.Brand href="/">UserStoryApp</Navbar.Brand>
      <Navbar.Toggle aria-controls="responsive-navbar-nav" />
      <Navbar.Collapse id="responsive-navbar-nav">
        <Nav className="header_link justify-content-end">
          <Link className='mx-2 nav-link' to="/">Home</Link>
          <Link className='mx-2 nav-link' to="/about">About</Link>
          <NavDropdown.Divider />
          {
            !isAuthenticated ?
            <>
              <Link className='mx-2 nav-link' to="/login">Login </Link>
              <Link className='mx-2 nav-link' to="/register">Register</Link>
            </>
            :
            <>
              <Link className='mx-2 nav-link' to="/my-stories">My Stories </Link>
              <Link className='mx-2 nav-link' to="/login" onClick={logoutUser}>Logout</Link>
            </>
          }
        </Nav>
      </Navbar.Collapse>
    </Container>
  </Navbar>
  )
}

export default Header