import React from 'react'

const About = () => {
  return (
    <div>
        <h3>About</h3>
        <p>Welcome to UserStoryApp v1 👋. This is a simple app that shows and saves user stories.</p>
    </div>
  )
}

export default About