import React, {useContext, useState, createContext, useEffect} from 'react'

const authContext = createContext()

const AuthContextProvider = ({ children }) => {
    const [isAuthenticated, setIsAuthenticated] = useState(false)
    const [exp, setExp] = useState()

    const initialState = {
        isAuthenticated,
        setIsAuthenticated,
        exp,
        setExp
    }

    useEffect(() => {
      const token = localStorage.getItem("access-token") || null

      if(token){
        setIsAuthenticated(true)
      }
    }, [])

  return (
    <authContext.Provider value={initialState}>
        {children}
    </authContext.Provider>
  )
}

export {
    AuthContextProvider,
    authContext
}