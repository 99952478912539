import axios from 'axios'

const token: String | null = localStorage.getItem("access-token");

export const getRequest = async (endpoint: string) => {
    let response = await axios
            .get(endpoint)
            .catch((error: any) => {
                return ("getRequest Axios error: " + error.response);
            });

    return response;
};


export const getData = async (endpoint: string) => {
    let response = await axios
            .get(endpoint, {
                headers : {
                    Authorization: `Bearer ${token}`
                }
            })
            .catch((error: any) => {
                return ("getRequest Axios error: " + error.response);
            });

    return response;
};



export const postRequest = async (endpoint: string, data: object) => {
    let response = await axios
            .post(endpoint, data)
            .catch((error: any) => {
                return ("getRequest Axios error: " + error.response);
            });

    return response;
};

export const postData = async (endpoint: string, data: object) => {
    try {
        const response = await axios.post(endpoint, data, {
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        });
        return response;
    } catch (err) {
        return (err);
    }
};

export const deleteData = async (endpoint: string) => {
    try {
        const response = await axios.delete(endpoint, {
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        });
        return response;
    } catch (err) {
        return (err);
    }
};